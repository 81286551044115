<template>
<div class="voucher-zaozhuang">
  <div class="page flex-col">
    <div class="bd1 flex-col"  v-lazy:background-image="require('../../assets/voucher/zaozhuang.png')">
          <div class="bd2 flex-col">
            <span class="info1">输入手机号查询</span>
            <div class="mod1 flex-col">
              <div class="bd3 flex-col">
                <van-field  class="voucher-field" v-model="receiveParams.mobile" autofocus="true" maxlength="11" type="tel" clearable   /> <!--@input="queryReceive()"-->
              </div>
             </div>
             <div v-for="(voucher,index) in voucherList" :key="index">
                <van-image v-if="voucher.status == 0" lazy-load @click="receiveVoucher(index, voucher.couponId)"  :class="voucher.imageClass" referrerpolicy="no-referrer" :src="voucher.imageUrl" />
                <van-image v-else :class="voucher.imageClass + ' receive-voucher'" lazy-load referrerpolicy="no-referrer" :src="voucher.imageUrl" />
             </div>
          </div>
     </div>
   </div>
 </div>
</div>
</template>
<script>
import { setToken } from '@/utils/auth'
import { downloadCoupon, queryReceiveCoupon } from "@/api/coupon";
import Vue from 'vue';
import { Image as VanImage, Field, Dialog, Lazyload } from 'vant';
Vue.use(Lazyload);

export default {
    name: 'VoucherZaozhuang',
    components: {
         [VanImage.name]: VanImage,
         [Field.name]: Field,
         [Dialog.name]: Dialog
    },
    data() {
        return {
          // 领取参数
          receiveParams: {
             couponId: '',
             mobile: '',
             couponNum: 1,
             acctEntityTp: '01'
          },
          // 查询参数
          queryParams: {
            searchType: '01',
            searchValue: '',
            //第一期活动编号
            //couponIds: '3102022060971249,3102022060971263,3102022060971264,3102022060971265,3102022060971266'
            //第二期活动编号
            //couponIds: '3102022061573201,3102022061573202,3102022061573203,3102022061573205,3102022061573206'
            //第三期活动编号
            //couponIds: '3102022062276057,3102022062276066,3102022062276071,3102022062276077,3102022062276078'
            //第四期活动编号
            //couponIds: '3102022062979258,3102022062979249,3102022062979244,3102022062979219,3102022062979187'
            //第五期活动编号
            //couponIds: '3102022070782728,3102022070782740,3102022070782749,3102022070782758,3102022070782762'
            //第六期活动编号
            //couponIds: '3102022071384549,3102022071384560,3102022071384562,3102022071384566,3102022071384568'
            //第七期活动编号
            couponIds: '3102022072688779,3102022072688788,3102022072688785,3102022072688786,3102022072688783'
          },
          // 优惠券列表
          voucherList: [
             {imageId: 1, couponId: 3102022072688779, status: 0, imageClass: 'img1', imageUrl: require('../../assets/voucher/10yuan.png')},
             {imageId: 2, couponId: 3102022072688788, status: 0, imageClass: 'pic1', imageUrl: require('../../assets/voucher/20yuan.png')},
             {imageId: 3, couponId: 3102022072688785, status: 0, imageClass: 'pic2', imageUrl: require('../../assets/voucher/40yuan.png')},
             {imageId: 4, couponId: 3102022072688786, status: 0, imageClass: 'img2', imageUrl: require('../../assets/voucher/80yuan.png')},
             {imageId: 5, couponId: 3102022072688783, status: 0, imageClass: 'pic3', imageUrl: require('../../assets/voucher/100yuan.png')},
          ],
        }
    },
   created() {
      this.onInit();
   },
   methods: {
      //初始化
      onInit() {
        // 设置Header
         setToken("0003");
      },
      //查询领券记录
      queryReceive() {
          if(this.receiveParams.mobile.length === 11) {
               this.queryParams.searchValue = this.receiveParams.mobile;
               //console.log("查询领券记录");
               //console.log(this.queryParams);
               queryReceiveCoupon(this.queryParams).then(response => {
                  if(response.code === 200) {
                     let obj = response.data;
                     let voucherL = this.voucherList;
                     Object.getOwnPropertyNames(obj).forEach(function(key){
                        if(obj[key] instanceof Object) {
                            voucherL[key]['status'] = obj[key].receiveStatus;
                        }
                     });
                     this.voucherList = voucherL;
                  }
               });

               /*let response = {'code': 200, 'msg': '操作成功', data: [
                  {"couponId": 3102022011938598, "operationType": "04","transTp": "04","receiveStatus": 0},
                  {"couponId": 2, "operationType": "04","transTp": "04","receiveStatus": 0},
                  {"couponId": 3, "operationType": "04","transTp": "04","receiveStatus": 0},
                  {"couponId": 4, "operationType": "04","transTp": "04","receiveStatus": 0},
                  {"couponId": 5, "operationType": "04","transTp": "04","receiveStatus": 0}
               ]};

               let obj = response.data;
               let voucherL = this.voucherList;
               Object.getOwnPropertyNames(obj).forEach(function(key){
                  if(obj[key] instanceof Object) {
                      voucherL[key]['status'] = obj[key].receiveStatus;
                  }
               });
               this.voucherList = voucherL;*/
          }
      },
      //领取优惠券
      receiveVoucher(index, couponId) {
          // 手机号验证
          let mobileRegExp = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
          if(mobileRegExp.test(this.receiveParams.mobile) == false) {
              Dialog.alert({
                message: '请输入正确的手机号码',
                theme: 'round-button',
              }).then(() => {});
              return false;
          }

          this.receiveParams.couponId = couponId;
          downloadCoupon(this.receiveParams).then(response => {
              if(response.code === 200) {
                 return Dialog.alert({ message: '恭喜您抢券成功，请前往云闪付APP“我的”-“奖励”查看票券，并进行核销使用。',theme: 'round-button'}).then(() => {
                    let imageClass = this.voucherList[index].imageClass;
                    this.voucherList[index].status = 1;
                    this.voucherList[index].imageClass = imageClass + " receive-voucher";
                 });
              }

              if(response.code == 674) {
                  return Dialog.alert({ message: '您抢券的时间不在活动时间范围之内。', theme: 'round-button'});
              }
              if(response.code == 672) {
                  return Dialog.alert({ message: '此次活动名额已领完，请前往云闪付APP查看更多优惠活动。',theme: 'round-button'}).then(() => {
                     let imageClass = this.voucherList[index].imageClass;
                     this.voucherList[index].status = 1;
                     this.voucherList[index].imageClass = imageClass + " receive-voucher";
                  });
              }
             if(response.code == 673) {
                  return Dialog.alert({ message: '您已领过优惠券，请前往云闪付APP“我的”-“奖励”查看票券，并进行核销使用。',theme: 'round-button'}).then(() => {
                     let imageClass = this.voucherList[index].imageClass;
                     this.voucherList[index].status = 1;
                     this.voucherList[index].imageClass = imageClass + " receive-voucher";
                  });
             }

            return Dialog.alert({ message: response.msg, theme: 'round-button'});
          });
      }
   }
};


</script>

<style scoped>@import '../../assets/voucher/common.css';</style>
<style scoped>@import '../../assets/voucher/index.response.css';</style>
<style scoped>
.voucher-field {
  background-color: rgba(253, 252, 236, 1);
  border-radius: 29px;
  width: 50.67vw;
  height: 6.64vw;
  padding-right:6vw;
}
.van-field__control {
  background-color: rgba(253, 252, 236, 1);
}
.receive-voucher {
  opacity:0.4;
  filter:alpha(opacity=40);
}
</style>


import request from '@/utils/request'
import { parseTime, createUniqueString } from "@/utils/common";

// 赠送优惠券
export function downloadCoupon(data) {
   const params = {
           transSeqId: data.transSeqId === undefined ? 'ZZH' + createUniqueString() : data.transSeqId,
           transTs: data.transTs === undefined ? parseTime(new Date(), "{y}{m}{d}") : data.transTs,
           couponId: data.couponId === undefined ? '' : data.couponId,
           mobile: data.mobile === undefined ? '' : data.mobile,
           cardNo: data.cardNo === undefined ? '' : data.cardNo,
           openId: data.openId === undefined ? '' : data.openId,
           acctEntityTp: data.acctEntityTp === undefined ? '' : data.acctEntityTp,
           couponNum: data.couponNum === undefined ? 1 : data.couponNum
   };
    return request({
        url: 'open/union/1.0/coupon/download',
        method: 'post',
        data: params
    })
}

// 查询领券记录
export function queryReceiveCoupon(query) {
   return request({
        url: 'open/union/1.0/coupon/queryReceive',
        method: 'get',
        data: query
    })
}
